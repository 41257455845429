import WalletUtil from "@/utils/wallet";
export default {
  data: function data() {
    return {
      pics: [{
        src: require('../assets/images/dae/1.jpg'),
        rotate: -5,
        at: 1
      }, {
        src: require('../assets/images/dae/2.jpg'),
        rotate: 5,
        at: 2
      }, {
        src: require('../assets/images/dae/3.jpg'),
        rotate: -5,
        at: 1.5
      }, {
        src: require('../assets/images/dae/4.jpg'),
        rotate: 5,
        at: 1.6
      }, {
        src: require('../assets/images/dae/5.jpg'),
        rotate: -5,
        at: 1
      }, {
        src: require('../assets/images/dae/6.jpg'),
        rotate: 5,
        at: 1.8
      }, {
        src: require('../assets/images/dae/7.jpg'),
        rotate: -5,
        at: 2
      }, {
        src: require('../assets/images/dae/8.jpg'),
        rotate: 5,
        at: 1
      }, {
        src: require('../assets/images/dae/9.jpg'),
        rotate: -5,
        at: 1.2
      }],
      links: [{
        logo: require('../assets/images/dae/link-1.png'),
        url: '#'
      }, {
        logo: require('../assets/images/dae/link-2.png'),
        url: '#'
      }, {
        logo: require('../assets/images/dae/link-3.png'),
        url: '#'
      }, {
        logo: require('../assets/images/dae/link-4.png'),
        url: '#'
      }, {
        logo: require('../assets/images/dae/link-5.png'),
        url: '#'
      }, {
        logo: require('../assets/images/dae/link-6.png'),
        url: '#'
      }, {
        logo: require('../assets/images/dae/link-7.png'),
        url: '#'
      }, {
        logo: require('../assets/images/dae/link-8.png'),
        url: '#'
      }],
      loading: false
    };
  },
  methods: {
    connectWallet: function connectWallet() {
      WalletUtil.connect();
    },
    mintNFT: function mintNFT() {
      var _this = this;

      if (this.loading) {
        return;
      }

      console.log(Contract.NFT);
      this.loading = true;
      Contract.NFT.mint().then(function (result) {
        console.log(result);
        _this.loading = false;
      }).catch(function (err) {
        console.log(err);
        _this.loading = false;
      });
    }
  }
};